const serviceTypes = {

    vendor: 1,
    donation: 2,
    medicine: 3,
    shipping: 4,
    talqah: 5,
    driveMe: 6,
}

export {serviceTypes}
