<template>
  <div class="driver-card">

    <div class="person-info">
      <div class="data">


        <img v-if="data.image && showImage" :src=data.image :alt=data.name @error="onError" class="person-img">
        <div v-else class="person-img">
     <span>
        <d-person-icon/>
     </span>
        </div>

        <div class="col">
          <div class="name">{{ data.name }}</div>
          <div class="phone">{{ data.phone }}</div>
          <div class="phone">{{$t('orders.driver_pending_orders_count')}} :<span class='text-success'> {{ data.count }}</span></div>
          <div class="text" >
            {{ data.vehicle }} -
            <span :class="data.status===1?'text-success':'text-danger'">
             {{ status }}
           </span>
          </div>
        </div>
      </div>
      <wameed-btn
          variant="gray"
          size="sm"
          :title="$t('orders.assign')"
          classes="text-book-18  rounded-12 w-unset px-4 success-btn"
          block
          @onClick="onClick"
      />
    </div>
  </div>
</template>

<script>
import {WameedBtn} from 'wameed-ui/dist/wameed-ui.esm'

export default {
  components: {WameedBtn},

  props: {
    data: null
  },
  data() {
    return {
      showImage: true,
    }
  },

  computed: {
    status() {
      if (this.data.status === 1) {
        return this.$t('status.available');
      }
      return this.$t('status.not_available');
    }
  },
  methods: {
    onError() {
      this.showImage = false;
    },
    onClick() {
      this.$emit('onClick');
    }
  }
}
;
</script>
