<template>
  <validation-observer
    ref='form'
    #default='{ invalid }'
    tag='form'
    class='repeater__form'
    style='overflow: visible'
  >
    <b-modal
      v-model='show'
      content-class='package-modal rounded-8'
      dialog-class='wameed-modal-md'
      no-fade
    >
      <template slot='modal-header-close'>
        <close-icon />
      </template>
      <template slot='modal-title'>
        <div class='d-block text-center'>
          <h3 class='text-reg-18'>
            {{ $t('status.change') }}
          </h3>
        </div>
      </template>


      <div class='text-danger mb-5'>
        {{ $t('status.warning_message') }}
      </div>

      <div class='d-flex flex-wrap' style='gap: 12px'>
        <div class='status-container clickable'
             @click='changeToActive(status)'
             :class="{
            'custom':    (status.code == currentStatusCode) ,
            'custom':    (activeStatus == status.id) ,
            'disabled':  !activeTypes.includes(status.type),
             }"
             v-for='status in getStatues' :style='`--color:#${status.color};--bgColor:${bgColor(status.color)}`'>
          <div class='status-container--text text'>
            {{ status.name }}
          </div>
        </div>
      </div>

      <template slot='modal-footer'>
        <wameed-btn
          classes='  text-med-14 text-white mx-0 rounded-8'
          :title="$t('btn.update')"
          :disabled='invalid'
          type='submit'
          variant='main'
          @onClick='checkWhatDialogToShow'
        />
        <wameed-btn
          classes='  text-med-14 text-font-secondary mx-0 rounded-8 '
          :title="$t('btn.cancel')"
          type='button'
          variant='gray'
          @onClick='closeModal()'
        />
      </template>


      <warning-modal
        variant='danger'
        iconName='o-warning-danger-icon'
        :visible='showConfirmModal'
        @close='showConfirmModal = false'

        @submitAction='changeStatus'
        :title="$t('status.modal.title')"
        :subTitle="$t('status.modal.desc')"
        :btnTitle="$t('common.change')"
      />

      <cancel-order-modal
        :visible='showCancelModal'
        @close='showCancelModal = false'
        :order='orderId'
        @onComplete='()=>onCancelComplete()'
      />

    </b-modal>
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { WameedBtn } from 'wameed-ui/dist/wameed-ui.esm';
import { mapActions, mapGetters } from 'vuex';
import WarningModal from '@/components/WarningModal.vue';
import CancelOrderModal from '@/views/pages/orders/components/cancelOrderModal.vue';


export default {
  components: {
    CancelOrderModal,
    WarningModal,
    ValidationProvider,
    ValidationObserver,
    WameedBtn
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    currentStatusType: {
      type: [Number, String],
      default: 0
    },
    currentStatusCode: {
      type: [Number, String],
      default: 0
    },

    orderId: {
      type: [Number, String],
      default: null
    }

  },

  data() {
    return {
      showConfirmModal: false,
      activeStatus: 0,
      activeStatusType: 0,
      showCancelModal: false,
      form: {
        note: '',
        reason: null
      },
      showSuccessModal: false
    };
  },
  computed: {
    ...mapGetters({
      getStatues: 'vendor/orders/getStatues'
    }),
    show: {
      get() {

        return this.visible;
      },
      set(value) {
        if (!value) {
          this.activeStatus = 0;
          this.$emit('close');
        }
      }
    },

    activeTypes() {
      let status = this.getStatues.find(x => x.type === this.currentStatusType);
      if (status)
        return status.can_change_to_type;
      return [];
    }

  },


  methods: {
    ...mapActions({
      cancel: 'vendor/orders/cancel',
      update: 'vendor/categories/update'
    }),
    statusStyle(status) {
      let isActive = status.code === this.currentStatusCode ? 'custom' : '';
      if (this.activeStatus === status.id)
        isActive = 'active';
      let can = status.can_change_to_type.includes(this.currentStatusType);
      let canClick = can ? 'clickable' : 'disabled';
      return `${isActive} ${canClick}`;

    },

    checkWhatDialogToShow() {
      //if the selected status is cancel
      //show the warning modal
      if(this.activeStatusType == 7) {
        this.showCancelModal = true;
      }else{
        this.showConfirmModal=true
      }
    },

    changeToActive(status) {

      this.activeStatus = status.id;
      this.activeStatusType = status.type;
    },
    bgColor(color) {
      let rgb = this.hexToRgb('#' + color);
      return `rgba(${rgb.r},${rgb.g},${rgb.b},.1)`;
    },
    hexToRgb(hex) {
      let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    },
    changeStatus() {
      if (this.activeStatus !== 0) {
        this.$store.dispatch('vendor/orders/changeOrderStatus',
          { id: this.orderId, status: this.activeStatus })
          .then(() => {
            this.$emit('onComplete', true);
            this.closeModal();
            this.$store.commit('loadingFinish', null, { root: true });
          });
      }
    },
    onCancelComplete(){
      this.$emit('onComplete', true);
      this.closeModal();
    },
    closeModal() {
      this.show = false;
      this.activeStatus = 0;
      this.showConfirmModal = 0;
      this.form.note = '';
    }
  }
};
</script>
