<template>
  <div class='wameed-dashboard-page-content'>

    <detail-print />
    <page-header
      :title="$t('orders.detail')"
      :paths='pagePath()'

    >
      <template v-slot:btns>
        <button type='button' class='outline-link' @click='printOrder'>
          <!--          <o-export-icon/>-->
          {{ $t('print.print') }}
        </button>
      </template>
    </page-header>
    <section class='wameed-dashboard-page-content_body products_create order-detail'>


      <infoSection
        :detail='detail'
        :add-price-btn-text='addPriceBtnText'
        @onAccept='accept'
        @onEdit='showConfirmEditModal=true'
        @onCancel='showCancelModal=true'
        @onAddPrices='showPricesModal=true'
        @onChangeStatus='changeOrderStatusModal=true'
        :edit='editCart'
      />

      <b-card
        no-body
        class='wameed-card mb-5'
        v-if='detail.cancel_note'
      >
        <div class=''>

          <b-card-header class='align-items-start tab-title'>
            <h5 class='text-med-18 text-font-main'>
              {{ $t('orders.table.cancel_reason') }}
            </h5>
          </b-card-header>


          <div class='order-note'>
            <div class='text-reg-16 box danger'> {{ detail.cancel_note }}</div>
          </div>

        </div>
      </b-card>

      <div class='card-info-section mb-5'>

        <person-info-card
          :class="'card-info'"
          :link='goToDriverDetail'
          :title="$t('orders.info.driver')"
          :info='detail.driver'
          :btn='driverBtnTitle'
          :enable-btn='detail.can_assign_driver'
          :btn-color="detail.driver.id==0?'btn-info':'btn-main'"
          @onClick='driverBtnOnClick'>
          <wameed-btn
            v-if='detail.driver.id!=0 && detail.can_assign_driver'
            variant='gray'
            size='sm'
            :title="$t('orders.reassign_driver')"
            :classes='`text-book-18  rounded-12 w-unset px-4 btn-info`'
            block
            @onClick='showDriverModal = true'
          />
        </person-info-card>

        <person-info-card
          :link='goToVendorDetail'
          :class="'card-info'"
          :title="$t('orders.info.vendor')"
          :info='detail.vendor'
          :btn="$t('common.show_details')"
          @onClick='showVendorDetail(detail.vendor.id)'
        />

        <person-info-card
          :link='goToClientDetail'
          v-if='detail.from_address'
          :enable-btn='detail.can_update_addresses'
          :class="'card-info'"
          :title="$t('orders.info.from')"
          :info='detail.from_address'
          :btn="$t('orders.edit_address')"
          :client='true'
          @onClick="showEditAddress('from')" />


        <person-info-card
          :link='goToClientDetail'
          :enable-btn='detail.can_update_addresses'
          :class="'card-info'"
          :title="detail.from_address==null ?$t('orders.info.customer') :$t('orders.info.to')"
          :info='detail.to_address'
          :btn="$t('orders.edit_address')"
          :client='true'
          @onClick="showEditAddress('to')" />


      </div>


      <b-card
        no-body
        class='wameed-card mb-5'
        v-if='vendorProducts.length>0'
      >
        <div class='vendor-products-section'>

          <b-card-header class='align-items-start tab-title'>
            <h5 class='text-med-18 text-font-main'>
              {{ $t('orders.products.title') }}
            </h5>
            <div class='search'>
              <text-input
                v-model='productSearch'
                icon='search-icon'
                is-append
                input-classes='text-reg-14 '
                :placeholder="$t('common.search') + ' ...'"
                field-classes='mb-0 search-form-control'
              />
            </div>
          </b-card-header>

          <div class='order-vendor-categories p-5'>

            <div v-for='cat in vendorProducts' :key='cat.id'>
              <template v-if='cat.products && cat.products.length>0'>
                <div class='category-name'>{{ cat.name }}</div>
                <div class='order-vendor-products'>

                  <div
                    v-for="product in cat.products.filter((prod)=>prod.name.indexOf(productSearch) > -1 ||productSearch=='')"
                    :key='product.id'>
                    <product-card :data='product' @onClick='()=>{productModalData = null;onProductClick(product.id)}' />
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </b-card>

      <b-card
        no-body
        class='wameed-card mb-5'
        v-if='detail.review'
      >
        <div class=''>

          <b-card-header class='align-items-start tab-title'>
            <h5 class='text-med-18 text-font-main'>
              {{ $t('table.rating') }}
            </h5>
          </b-card-header>

          <div class='order-detail-info p-5'>


            <div class='flex'>
              <div class='tile-text'>
                <div class='text'>{{ $t('table.platform_rating') }}</div>
                <div class='value'>
                  <customer-rate :rate='detail.review.platform_rating' />
                </div>
              </div>
              <div class='tile-text'>
                <div class='text'>{{ $t('table.rated_at') }}</div>
                {{ formatDate(detail.review.date) }}
              </div>
            </div>

            <div class='flex'>
              <div class='tile-text'>
                <div class='text'>{{ $t('table.vendor_rating') }}</div>
                <div class='value'>
                  <customer-rate :rate='detail.review.vendor_rating' />
                </div>
              </div>
              <div class='tile-text'>
                <div class='text'>{{ $t('table.driver_rating') }}</div>
                <div class='value'>
                  <customer-rate :rate='detail.review.driver_rating' />
                </div>
              </div>

            </div>

            <div class='divider' />
            <div>
              <div class='tile-text col'>
                <div class='text'>{{ $t('table.comment') }}</div>
                <div class='value'>{{ detail.review.comment }}</div>
              </div>


            </div>
          </div>
        </div>
      </b-card>


      <div class='order-detail-table'>
        <b-card
          no-body
          class='wameed-card'
        >
          <b-card-header class='align-items-start tab-title'>
            <h5 class='text-med-18 text-font-main'>
              {{ $t('orders.detail') }}
            </h5>
          </b-card-header>


          <div class='w-table '>

            <order-table
              v-if='detail.order'
              :order='detail.order'
              :tableFields='detail.tableFields'
              :editOrder='editCart'
              @onCartEdit='onCartEdit'
            />

            <div class='p-5 d-flex justify-content-between'>
              <div></div>
              <div class='bill-detail px-4 '>
                <bill-price-tile
                  v-if='detail.vendorId'
                  :data='detail.bill.order'
                  :title="$t('orders.bill.order')"
                />
                <template v-if='detail.bill'>

                  <bill-price-tile
                    v-if='serviceTypesIsMedicine'
                    :data='detail.bill.order'
                    :title="$t('orders.medical_price')"
                  />

                  <bill-price-tile
                    v-if='detail.vendorId'
                    :data='detail.bill.service'
                    :title="$t('orders.bill.service')"
                    type='service'
                  />

                  <bill-price-tile
                    :data='detail.bill.delivery'
                    :title="$t('orders.bill.delivery')"
                  />
                  <bill-price-tile
                    v-if='detail.vendorId'
                    :data='detail.bill.coupon'
                    :title="$t('orders.bill.discount')"
                    type='coupon'
                  />

                </template>
                <div class='h-divider'></div>
                <bill-price-tile
                  :data='{price:detail.total_price}'
                  :title="$t('table.total')"
                  type='total'
                />
              </div>
            </div>

            <div class='order-note' v-if='detail.note'>
              <div class='text-reg-16 mb-2'>{{ $t('orders.customer_note') }}</div>
              <div class='text-reg-16 box danger'> {{ detail.note }}</div>
            </div>
            <div class='order-note' v-if='detail.images'>
              <div class='text-reg-16 mb-2'>{{ $t('orders.images') }}</div>
              <div class='images-box'>

                <a target='_blank' v-for='(image,index) in detail.images' :key='index' :href='image'>
                  <img class='order-image' :src='image' alt=''>
                </a>

              </div>
            </div>
          </div>
        </b-card>
        <b-card class=' wameed-card' v-if='editCart'>
          <div class='d-flex justify-content-between'>

            <wameed-btn
              variant='main'
              size='md'
              :title="$t('common.save_edit')"
              classes='text-book-18  rounded-12 w-unset px-4 m-0 main-btn'
              block
              @onClick='saveOrderChanges'
            />
            <wameed-btn
              variant='gray'
              size='md'
              :title="$t('common.cancel')"
              classes='text-book-18  rounded-12 w-unset px-4 m-0 gray-btn'
              block
              @onClick='cancelOrderEdit'
            />
          </div>
        </b-card>
      </div>
    </section>

    <driver-modal
      v-if='detail.id'
      :visible='showDriverModal'
      :order-id='detail.id'
      @close='showDriverModal = false'
    />
    <product-detail-modal
      :visible='showProductModal'
      :order-id='detail.id'
      :cart-product='productModalData'
      @close='showProductModal =false'
      @onSubmit='updateCartItems'
    />
    <edit-address-modal
      :visible='showEditAddressModal'
      :type='editAddressModalType'
      @close='showEditAddressModal = false'
    />
    <edit-prices-modal
      :edit-order-price='canEditOrderPrice'
      :price-text='deliveryPriceText'
      :visible='showPricesModal'
      @close='showPricesModal = false'
    />
    <edit-talqah-order-modal
      :visible='showEditTalqahModal'
      @close='showEditTalqahModal = false'
      :edit-order-type='editOrderType'
      :is-medicine='isMedicine'
      :modalData='{
          note:detail.note,
          order:detail.order
        }'
      @onComplete='()=>loadData()'
    />


    <change-order-status-modal
      :visible='changeOrderStatusModal'
      @close='changeOrderStatusModal = false'
      :order-id='detail.id'
      :current-status-type='detail.status_type'
      :current-status-code='detail.status_code'
      @onComplete='()=>loadData()'
    />

    <cancel-order-modal
      :visible='showCancelModal'
      @close='showCancelModal = false'
      :order='detail.id'
      @onComplete='()=>loadData()'
    />


    <warning-modal
      variant='main'
      :iconName='driverContactModal.icon'
      :visible='showDriverContactModal'
      @close='showDriverContactModal = false'
      @submitAction='toggleAction'
      :title='driverContactModal.title'
      :subTitle='driverContactModal.subTitle'
      :btnTitle='driverContactModal.btn'
    />

    <warning-modal
      variant='main'
      :iconName="'edit-icon'"
      :visible='showConfirmEditModal'
      @close='showConfirmEditModal = false'

      @submitAction='editOrder'
      :title="$t('orders.modal.edit.title')"
      :subTitle="$t('orders.modal.edit.sub_title')"
      :btnTitle="$t('common.edit')"
    />


  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import { WameedTextInput as TextInput } from 'wameed-ui/dist/wameed-ui.esm';
import PageHeader from '@/components/wameed/WameedPageHeader.vue';

import BillPriceTile from '@/views/pages/orders/components/billPriceTile';
import OrderTable from '@/views/pages/orders/components/orderTable';
import PersonInfoCard from '@/views/pages/orders/components/personInfoCard';
import CustomerRate from '@/components/customerRate';
import DriverModal from '@/views/pages/orders/components/driverModal';
import ProductDetailModal from '@/views/pages/orders/components/productDetailModal';
import EditAddressModal from '@/views/pages/orders/components/editAddressModal';
import EditPricesModal from '@/views/pages/orders/components/editPricesModal';
import CancelOrderModal from '@/views/pages/orders/components/cancelOrderModal';
import WarningModal from '@/components/WarningModal';
import InfoSection from '@/views/pages/orders/InfoSection';
import ProductCard from '@/views/pages/orders/components/productCard';
import { serviceTypes } from '@/enums/serviceType.enum';
import EditTalqahOrderModal from '@/views/pages/orders/components/editTalqahOrderModal';
import ChangeOrderStatusModal from '@/views/pages/orders/components/changeOrderStatusModal.vue';
import DetailPrint from '@/views/pages/orders/detail-print.vue';


export default {
  components: {
    DetailPrint,
    ChangeOrderStatusModal,
    EditTalqahOrderModal,
    ProductDetailModal,
    ProductCard,
    TextInput,
    InfoSection,
    WarningModal,
    EditAddressModal,
    EditPricesModal,
    DriverModal,
    CustomerRate,
    PersonInfoCard,
    OrderTable,
    BillPriceTile,
    CancelOrderModal,
    PageHeader
  },
  props: {
    path: Array
  },
  data() {
    return {
      driverContactModal: {
        icon: 'o-show-icon',
        title: this.$i18n.t('orders.driverModal.show.title'),
        subTitle: this.$i18n.t('orders.driverModal.show.sub_title'),
        btn: this.$i18n.t('orders.driverModal.show.btn')
      },
      productModalData: null,
      platformOrderData: {},
      productSearch: '',
      changeOrderStatusModal: false,
      showProductModal: false,
      isMedicine: false,
      editOrderType: false,
      showEditTalqahModal: false,
      showConfirmEditModal: false,
      editCart: false,
      showDriverToCustomer: false,
      showCancelModal: false,
      showDriverContactModal: false,
      showDriverModal: false,
      showPricesModal: false,
      showEditAddressModal: false,
      editAddressModalType: ''
    };
  },
  computed: {
    ...mapGetters({
      detail: 'vendor/orders/getDetail',
      vendorProducts: 'vendor/orders/getProducts'
    }),

    goToDriverDetail() {
      if (this.detail && this.detail.driver && this.detail.driver.id != 0)
        return {
          name: 'users-drivers-detail',
          params: { lang: this.$i18n.locale, id: this.detail.driver.id }
        };
    },

    goToClientDetail() {
      return {
        name: 'users-clients-detail',
        params: { lang: this.$i18n.locale, id: this.detail.userId }
      };
    },

    goToVendorDetail() {
      if (this.detail && this.detail.vendor && this.detail.vendor.id != 0)
        return {
          name: 'users-vendors-detail',
          params: { lang: this.$i18n.locale, id: this.detail.vendor.id }
        };
    },

    driverBtnTitle() {
      if (this.detail.driverId == 0 || this.detail.driverId == null) {
        return this.$i18n.t('orders.assign_driver');
      } else {
        if (this.detail.show_driver == 1) {
          return this.$i18n.t('orders.hide_driver_to_user');
        } else {
          return this.$i18n.t('orders.show_driver_to_user');
        }
      }
    },

    addPriceBtnText() {
      if (this.detail.service_type_id === serviceTypes.shipping) {

        return this.$i18n.t('orders.shipping_add_price');
      } else if (this.detail.service_type_id === serviceTypes.medicine) {

        return this.$i18n.t('orders.medical_add_price');
      }
      return '';
    },


    deliveryPriceText() {
      if (this.detail.service_type_id === serviceTypes.shipping) {

        return this.$i18n.t('orders.shipping_price');
      } else if (this.detail.service_type_id === serviceTypes.medicine) {

        return this.$i18n.t('orders.delivery_price');
      }
      return '';
    },


    canEditOrderPrice() {
      return this.detail.service_type_id === serviceTypes.medicine;
    },

    serviceTypesIsMedicine() {
      return this.detail.service_type_id === serviceTypes.medicine;
    }


  },
  methods: {

    printOrder() {


      //get the print section element without open print dialog
      let printContents = document.querySelector('.print-order-doc');
      let originalContents = document.body.innerHTML;
      //convert printContents to canvas
      // html2canvas(printContents).then(function(canvas) {
      // document.body.innerHTML = '';
      //set the canvas to the print section
      // document.body.appendChild(canvas);
      //open the print dialog
      window.print();
      // //remove the canvas
      // document.body.removeChild(canvas);
      // //set the original html back
      // document.body.innerHTML = originalContents;
      // document.close()
      // });

      return false;

      //set the print section with the current order detail
      // document.body.innerHTML = printContents;
      // window.print();
      // document.body.innerHTML = originalContents;
      // window.addEventListener("afterprint", () => self.close);
      //
      // window.close();

    },
    updateCartItems(data) {
      console.log('--------------------------------');
      console.log(data);
      console.log('--------------------------------');
      this.productModalData = null;


      this.$store.dispatch('vendor/orders/updateCart', data);


      // this.form.products = this.form.products.filter((item) => {
      //   return item.time !== data.time;
      // });
      // this.form.products.push(data);
      this.showProductModal = false;
    },

    cancelOrderEdit() {
      this.editCart = false;
      this.loadData();
    },

    saveOrderChanges() {
      this.$store.dispatch('vendor/orders/saveOrderChanges', this.$route.params.id)
        .then(() => {
          this.$store.dispatch('vendor/orders/unsetCart');
          this.editCart = false;
          this.loadData();
        });
    },

    showEditAddress(type) {
      this.showEditAddressModal = true;
      this.editAddressModalType = type;
    },

    pagePath() {
      if (this.path) {
        return this.path;
      }
      let type = this.$route.meta.type;
      if (type) {
        return [{ title: this.$i18n.t('nav.orders.' + type), link: 'orders-' + type }];
      } else {
        return [];
      }
    },
    showVendorDetail(id) {
      this.$router.push({
        name: 'users-vendors-detail',
        params: { lang: this.$i18n.locale, id }
      });
    },

    loadData() {
      this.$store.dispatch('vendor/orders/detail', this.$route.params.id);
    },

    accept() {
      this.$store.dispatch('vendor/orders/accept', this.detail.id).then(() => {
          this.loadData();
        }
      );
    },
    onCartEdit(data) {
      if (this.editCart) {
        this.productModalData = data;
        let link = this.detail.vendorId + '/' + data.id;

        this.$store.dispatch('vendor/orders/productDetail', link).then(() => {
            this.showProductModal = true;
          }
        );
      }
    },
    onProductClick(id) {
      this.$store.dispatch('vendor/orders/productDetail', this.detail.vendorId + '/' + id).then(() => {
          this.showProductModal = true;
        }
      );
    },
    async editOrder() {
      this.isMedicine = this.detail.service_type_id === serviceTypes.medicine;
      if (this.detail.service_type_id === serviceTypes.vendor) {
        this.$store.dispatch('vendor/orders/getVendorProducts', this.detail.id).then(() => {
            this.showConfirmEditModal = false;
            this.editCart = true;
          }
        );
      } else if (this.detail.service_type_id === serviceTypes.talqah) {
        await this.$store.dispatch('vendor/orders/getOrderTypes');
        this.$store.dispatch('vendor/orders/getVehicleTypes').then(() => {
            this.showConfirmEditModal = false;
            this.showEditTalqahModal = true;
            this.editOrderType = true;
          }
        );
      } else {
        this.$store.dispatch('vendor/orders/getVehicleTypes').then(() => {
            this.showConfirmEditModal = false;
            this.showEditTalqahModal = true;
            this.editOrderType = false;
          }
        );
      }
    },


    driverBtnOnClick() {
      if (this.detail.driverId == 0 || this.detail.driverId == null) {
        this.showDriverModal = true;
      } else {
        if (this.detail.show_driver == 1) {
          this.driverContactModal = {
            icon: 'o-hide-icon',
            title: this.$i18n.t('orders.driverModal.hide.title'),
            subTitle: this.$i18n.t('orders.driverModal.hide.sub_title'),
            btn: this.$i18n.t('orders.driverModal.hide.btn')
          };
          this.showDriverToCustomer = 0;
        } else {
          this.driverContactModal = {
            icon: 'o-show-icon',
            title: this.$i18n.t('orders.driverModal.show.title'),
            subTitle: this.$i18n.t('orders.driverModal.show.sub_title'),
            btn: this.$i18n.t('orders.driverModal.show.btn')
          };
          this.showDriverToCustomer = 1;
        }

        this.showDriverContactModal = true;
      }
    },


    toggleAction() {
      this.$store.dispatch('vendor/orders/toggleDriver', {
        id: this.detail.id,
        status: this.showDriverToCustomer
      });
      this.showDriverContactModal = false;
    }
  },

  watch: {
    $route(to, from) {
      if (from !== to) {
        this.loadData();
      }
    }
  },

  beforeCreate() {
    this.$store.dispatch('vendor/orders/detail', this.$route.params.id);
    this.$store.dispatch('vendor/orders/cancelReasons');
    this.$store.dispatch('vendor/orders/statues', this.$route.params.id);
  }

};
</script>